import React from 'react'
import { Link } from 'gatsby'
import ReactMarkdown from 'react-markdown'


const DetailedBlog = ({ blogDetail }) => {
    return (
        <div className='blog-details pb-100'>
            <div className='back-btn'>
                <Link
                    to="/blogs/"
                    className="default-btn"
                >
                    <i className="flaticon-left"></i>Back to Blog<span></span>
                </Link>
            </div>

            <div className='container pt-70'>
                <div className='complete-blog'>

                    <div className='blog-header'>
                        <h1 className='text-center'>{blogDetail?.metaTitle}</h1>
                    </div>

                    <div className='blog-content'>
                        {blogDetail?.contentBody.map((detail, index) => (
                            <div className='content-body' key={index}>
                                {detail?.blogImage ? 
                                <div>
                                    {detail?.head2 && <h2 className='text-center'>{detail?.head2}</h2>}
                                    <div className='blog-image my-4'>
                                        <img src={detail?.blogImage?.url} alt={detail?.blogImage?.alternativeText} />
                                    </div>
                                </div> :
                                (detail?.head2 && <h2>{detail?.head2}</h2>)
                                }
                                <ReactMarkdown>{detail?.paragraph?.data?.paragraph}</ReactMarkdown>
                                {detail?.contentItem.length > 0 && 
                                    <div className='list'>
                                        <ul>
                                            {detail?.contentItem.map((detailList, index) => (
                                            <li className='content-item' key={index}>
                                                {detailList?.head3 && <h3>{detailList?.head3}</h3>}
                                                <ReactMarkdown>{detailList?.paragraph?.data?.paragraph}</ReactMarkdown>
                                            </li>
                                            ))}  
                                        </ul>
                                    </div>
                                }
                                
                            </div>
                        ))}
                        {blogDetail?.blogConclusion && 
                            <div className='content-body'>
                                <h3>{blogDetail?.blogConclusion?.ConclusionHeading}</h3>
                                <ReactMarkdown>{blogDetail?.blogConclusion?.conclusionText?.data?.conclusionText}</ReactMarkdown>
                            </div>
                        }
                    </div>

                    <div className='blog-info pt-5 pb-4 mt-5'>
                        <ul className='d-flex justify-content-between align-item-center p-0 m-0'>
                            <li className='author-link'>
                                <Link to={blogDetail?.author?.slug}>
                                    <i className='bx bx-user-circle'></i> {blogDetail?.author?.authorName}
                                </Link>
                            </li>
                            <li className='text-sm'>
                                <i className='bx bx-calendar'></i> {blogDetail?.date}
                            </li>
                            <li className="text-sm">
                                <i className='bx bx-time-five'></i>04 min read
                            </li>
                        </ul>
                    </div>

                </div>
            </div>

            <div className='blog-border'></div>
            
        </div>
    )
}

export default DetailedBlog