import React from 'react'
import Layout from '../components/App/Layout'
import Navbar from '../components/App/Navbar'
import Footer from '../components/App/Footer'
import DetailedBlog from '../components/Blogs/DetailedBlog'
import Header from '../components/App/Header'

const Blog = ( context ) => {
  const blogDetail = context?.pageContext?.blog
  const { metaTitle, metaDescription, slug, scriptTag } = blogDetail

  return (
    <Layout>
      <Header metaTitle={metaTitle} metaDescription={metaDescription} slug={slug} scriptTag={scriptTag?.data?.scriptTag} />
      <Navbar />
      <DetailedBlog blogDetail={blogDetail}/>
      <Footer />
    </Layout>
    )
}

export default Blog